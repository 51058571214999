import moment from "moment";

export const parseRange = (rangeVal) => {
  if (!rangeVal) {
    return "";
  }

  const tmp = rangeVal.split("||").map((range) => {
    const values = range.split("|").map((value) => value?.trim());
    const firstValueParts = values[0].split("#");
    const firstValue = firstValueParts[0].trim();
    const firstUnit = firstValueParts[1] ? firstValueParts[1].trim() : "";

    const secondValueParts = values[1]
      ? values[1].split("#")
      : [undefined, undefined];
    const secondValue = secondValueParts[0] ? secondValueParts[0].trim() : "";
    const secondUnit = secondValueParts[1] ? secondValueParts[1].trim() : "";

    let result = "";

    if (firstValue) {
      result += `${firstValue}`;
    }

    if (firstValue && secondValue) {
      result += " to ";
    }

    if (secondValue) {
      result += `${secondValue}`;
    }
    if (firstUnit && secondUnit) {
      if (firstUnit === secondUnit) {
        result += ` ${firstUnit}`;
      } else {
        result = `${firstValue} ${firstUnit} to ${secondValue} ${secondUnit}`;
      }
    } else if (firstUnit) {
      result += ` ${firstUnit}`;
    } else if (secondUnit) {
      result += ` ${secondUnit}`;
    }

    return result;
  });

  return tmp.join(" , ");
};

export const accuracyFormat = (value) => {
  const regex = /^\d*\.?\d+(#.*)?$|^\d*\.?\d+[%]*[+]*\d*digit(#.*)?$/;
  return regex.test(value) ? `± ${parseRange(value)}` : parseRange(value);
};

export const DateisValid = (date, formate = "DD-MM-YYYY") => {
  if (date) {
    return moment(date).format(formate);
  }
  return "-";
};

export const DataisValid = (data) => {
  if (data) {
    return data;
  }
  return "";
};

export const parseEnvironment = (value, unit) =>{
  return value?.includes(unit)
    ? value.replace("#", " ")
    : value
    ? value + " " + unit
    : "\u00A0";
}
